<template>
  <v-dialog
    v-model="isDialogOpen"
    persistent
    fullscreen
    transition="fade-transition"
  >
    <v-card color="primary">
      <div
        class="tw-flex tw-h-full tw-flex-col tw-justify-center tw-items-center tw-p-5"
      >
        <div class="tw-relative">
          <v-progress-circular
            color="secondary"
            indeterminate
            width="6"
            size="256"
          >
            <v-img
              src="@/assets/svg/logo.svg"
              contain
              aspect-ratio="1"
              width="196px"
            />
          </v-progress-circular>
          <div class="tw-absolute tw-top-full tw-p-3 tw-text-center tw-w-full">
            Copyright &copy; {{ new Date().getFullYear() }}
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
  const isDialogOpen = ref(true)

  const { loadingDialogInstance } = useLoadingDialog()

  loadingDialogInstance.open = () => {
    isDialogOpen.value = true
  }

  loadingDialogInstance.close = () => {
    isDialogOpen.value = false
  }
</script>
