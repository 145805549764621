<template>
  <v-app>
    <NavBar />

    <v-main>
      <NuxtPage />
    </v-main>

    <Footer />

    <GetTheAppDialog />
    <LoadingDialog />
  </v-app>
</template>
