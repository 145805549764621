<template>
  <v-dialog v-model="isDialogOpen" persistent fullscreen>
    <v-card color="primary">
      <div
        class="tw-flex tw-h-full tw-flex-col tw-justify-center tw-items-center tw-p-5"
      >
        <div class="tw-max-w-32">
          <img src="@/assets/svg/logo.svg" />
        </div>
        <div class="tw-text-xl">Download the App now!</div>
        <GetTheApp class="tw-mb-3" />
        <VisitOurWebsiteBtn class="tw-mb-2" />
        <div>Copyright &copy; {{ new Date().getFullYear() }}</div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
  const { getTheAppDialogInstance } = useGetTheAppDialog()
  const isDialogOpen = ref(false)
  getTheAppDialogInstance.open = () => {
    isDialogOpen.value = true
  }

  // always reset on before mount
  onBeforeMount(() => {
    isDialogOpen.value = false
  })
</script>
